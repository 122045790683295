import store from "@/store/index";

export interface AppPlayerMatchesRootListFormCreate {
  user_id: string;
  organization_id: string;
  match_category_id: string;
  content: {
    date: string;
    fields: any;
  };
}

export class AppPlayerMatchesRootListFormCreate implements AppPlayerMatchesRootListFormCreate {
  user_id: string;
  organization_id: string;
  match_category_id: string;
  content: {
    date: string;
    fields: any;
  };

  constructor() {
    this.user_id = store.getters.getAuthentication.id ?? "";
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.match_category_id = "";
    this.content = {
      date: "",
      fields: [],
    };
  }
}