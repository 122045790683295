
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AppCommonMatchCategory} from "@/models/app/common/match-category";

// Components
import {
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElDatePicker,
} from "element-plus";

@Options({
  props: [
    "matchCategories",
  ],
  components: {
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElDatePicker,
  },
})
export default class AppPlayerMatchesRootFilterIndexVue extends Vue {
  matchCategories: Array<AppCommonMatchCategory> = [];

  formData: any = {
    filter: {
      user_id: store.getters.getAuthentication.id,
      match_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  getMatchesByFilter(): void {
    this.$emit("getMatchesByFilter", this.formData);
  }
}
