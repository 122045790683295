
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPlayerMatchesRootListFormCreate} from "@/models/app/player/matches/root/list/form/create";
import {AppCommonMatchCategory} from "@/models/app/common/match-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElTimeSelect,
  ElButton,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "matchCategories",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElSelect,
    ElOption,
    ElDatePicker,
    ElTimeSelect,
    ElButton,
    Plus,
  },
})
export default class AppPlayerMatchesRootListFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  matchCategories: Array<AppCommonMatchCategory> = [];

  formData: AppPlayerMatchesRootListFormCreate = new AppPlayerMatchesRootListFormCreate();
  formDataRules = {
    user_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    organization_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    match_category_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
  };

  matchCategory: AppCommonMatchCategory = new AppCommonMatchCategory();

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  setMatchCategory(): void {
    const matchCategory = this.matchCategories.find((item: AppCommonMatchCategory) => {
      return item.id === this.formData.match_category_id;
    });

    if (matchCategory) {
      this.matchCategory = matchCategory;

      const fields = [];
      this.matchCategory.content.fields.forEach((item: any) => {
        fields.push({
          value: "",
          name: item.name,
          property: item.property,
        })
      });
      this.formData.content.fields = fields;
    }
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/match/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getMatches');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppPlayerMatchesRootListFormCreate();
    this.matchCategory = new AppCommonMatchCategory();
  }
}
